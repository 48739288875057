.navbar {
  background-color: #2e2e2e;
  margin: 0;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.hamburger {
  color: #c0c0c0;
}

.hamburger:hover {
  color: rgb(0, 153, 255);
}

.hamburger:focus {
  box-shadow: none;
}

.navbar div {
  margin: 0;
  padding: 0;
  justify-content: center;
}

.navbar div a {
  text-decoration: none;
  font-weight: bold;
  padding: 5px 25px;
  color: #c0c0c0;
}

.navbar div a:hover {
  color: rgb(0, 153, 255);
}

.navbar div a.active {
  color: rgb(255, 255, 255);
  border-bottom: 3px solid rgb(0, 153, 255);
}
