.navBtns {
  text-align: center;
  margin: 0 auto;
}

.navBtns .next {
  position: absolute;
  width: 100px;
  bottom: 100px;
  right: 20px;
  background-color: #3e3f42;
  border: 3px solid rgb(187, 187, 187);
  border-radius: 25px;
  padding: 5px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-decoration: none;
}

.navBtns .next:active {
  transform: scale(90%);
}

.navBtns .next:hover {
  background-color: rgb(110, 110, 110);
  border: 3px solid rgb(255, 255, 255);
  border-radius: 25px;
  padding: 5px;
  color: rgb(255, 255, 255);
  transition: 250ms;
}

.navBtns .previous {
  position: absolute;
  width: 100px;
  bottom: 100px;
  left: 20px;
  background-color: #3e3f42;
  border: 3px solid rgb(187, 187, 187);
  border-radius: 25px;
  padding: 5px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-decoration: none;
}

.navBtns .previous:active {
  transform: scale(90%);
}

.navBtns .previous:hover {
  background-color: rgb(110, 110, 110);
  border: 3px solid rgb(255, 255, 255);
  border-radius: 25px;
  padding: 5px;
  color: rgb(255, 255, 255);
  transition: 250ms;
}
