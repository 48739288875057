.resourceDemo {
  padding: 20vh 10vw;
  margin: 0;
}

.title {
  font-size: 34px;
  color: #c0c0c0;
}

.subtitle {
  font-size: 20px;
  color: #c0c0c0;
}

.separator {
  color: #c0c0c0;
  margin: 40px 0px;
}

.sectionTitle {
  color: #c0c0c0;
  margin: 40px 0px 20px 0px;
  text-align: center;
}

.allItems {
  justify-content: center;
  display: flex;
}

.allItems,
.itemBox {
  text-align: center;
}

.itemBox {
  padding: 0;
  margin: 0;
}

.resources,
.structures,
.tools {
  display: flex;
  color: white;
  justify-content: center;
  flex-wrap: wrap;
}

.sword {
  display: flex;
  color: white;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.icon {
  max-width: 60px;
  padding-bottom: 10px;
}

.userItem {
  border: 3px solid white;
  border-radius: 15px;
  padding: 20px;
  margin: 25px;
  text-transform: capitalize;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  max-width: 250px;
}

.available {
  background-color: rgb(0, 119, 0);
}

.unavailable {
  background-color: rgb(119, 0, 0);
}

.btn {
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 5px 15px;
  background-color: rgb(0, 153, 255);
  border-radius: 50px;
  border: none;
}

.btn:active {
  transform: scale(90%);
}

.btn:hover {
  background-color: rgb(68, 180, 255);
  transition: 250ms;
}

.winner {
  position: fixed;
  width: 75vw;
  height: 75vh;
  top: 12.5%;
  left: 12.5%;
  z-index: 10;
  text-align: right;
  padding: 5%;
  border: 3px solid rgb(68, 180, 255);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  background-image: url("../../images/stars.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.winnerIcon {
  max-height: 50px;
}

.winnerTitle {
  color: black;
  background-color: white;
  box-shadow: 0px 0px 10px 10px white;
}

.winnerSubtitle {
  color: black;
  font-size: 20px;
  background-color: white;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  padding: 20px 0px;
  box-shadow: 0px 0px 10px 10px white;
}

.winnerBtn {
  color: white;
  font-size: 24px;
}
