.chatDemo {
  padding: 20vh 10vw;
  margin: 0;
  position: relative;
}

.intro {
  padding-bottom: 50px;
}

.title {
  font-size: 34px;
  color: #c0c0c0;
}

.subtitle {
  font-size: 20px;
  color: #c0c0c0;
}

.seb {
  text-align: center;
}

.sebImg {
  max-width: 400px;
}

.options {
  text-align: center;
}

/* list  /////////////////////////////////////////////////////*/

.textListBox {
  padding: 10px;
  list-style-type: none;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.25);
}

.textListTitle {
  color: white;
}

.textList {
  color: white;
  text-align: right;
  height: 500px;
  padding: 10px;
  overflow: auto;
  background-color: #c0c0c0;
  box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.textList li {
  background-color: rgb(0, 153, 255);
  padding: 10px 15px;
  margin: 10px;
  float: right;
  clear: both;
  list-style-type: none;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  text-transform: capitalize;
  word-break: break-word;
}

.textList > li:nth-child(even) {
  background-color: rgb(46, 46, 46);
  float: left;
  clear: both;
}

/* Code Attribution */
.attribution {
  padding: 40px 0px;
  left: 0;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 12px;
  width: 100%;
  z-index: -1;
}
