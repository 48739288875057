.footer {
  position: absolute;
  bottom: 0;
  margin: 0;
  background-color: rgb(20, 20, 20);
  padding: 5px;
  text-align: center;
  width: 100%;
  z-index: -1;
}

.footer-content {
  color: #c0c0c0;
  text-align: center;
  padding: 0px;
  margin: 0px;
}
