.input {
  border-radius: 50px;
  border-color: rgb(0, 153, 255);
  background-color: rgba(255, 255, 255, 0.75);
  min-width: 10vw;
  max-width: 60vw;
}

.input,
.input::placeholder {
  color: rgba(0, 0, 0, 0.65);
  padding: 0px 5px;
}

.input:focus {
  background-color: white;
}

.btn {
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 10px 30px;
  background-color: rgb(0, 153, 255);
  border-radius: 50px;
  border: none;
}
