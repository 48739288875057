.listDemo {
  padding: 20vh 10vw;
  margin: 0;
}

.intro {
  padding-bottom: 50px;
}

.title {
  font-size: 34px;
  color: #c0c0c0;
}

.subtitle {
  font-size: 20px;
  color: #c0c0c0;
}

.listTitle {
  text-align: center;
  color: #c0c0c0;
}

.list ul {
  padding: 10px;
  list-style-type: none;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.25);
}

.list li {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  color: white;
  text-transform: capitalize;
}

.list li * {
  margin: 0px 30px;
}

.deleteBtn {
  color: rgba(255, 255, 255, 0.55);
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0px;
  padding: 0px;
  margin: 0px;
}

.deleteBtn:hover {
  color: rgba(255, 0, 0, 1);
  transform: scale(120%);
  transition: 250ms;
}

.listInput,
.listInput::placeholder {
  color: rgba(0, 0, 0, 0.65);
  padding: 0px 5px;
}

.listInput {
  border-radius: 50px;
  border-color: rgb(0, 153, 255);
  background-color: rgba(255, 255, 255, 0.75);
  min-width: 10vw;
  max-width: 60vw;
}

.listInput:focus {
  background-color: white;
}

.btn {
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 10px 30px;
  margin: 10px 20px;
  background-color: rgb(0, 153, 255);
  border-radius: 50px;
  border: none;
}

.btn:active {
  transform: scale(90%);
}

.btn:hover {
  background-color: rgb(68, 180, 255);
  transition: 250ms;
}
