.welcome {
  padding: 20vh 10vw;
  margin: 0;
}

.title {
  font-size: 34px;
  color: #c0c0c0;
}

.subtitle {
  font-size: 20px;
  color: #c0c0c0;
}
